<template>
    <div class="body">
        <!-- 头部导航 -->
        <div class="flex-item ">
            <div class="backcolor">
                <span class="iconfont icon-fanhui " @click="Goblak">返回</span>
            </div>
            <div class="backphoto">
                <div class="close" v-show="keys" @click="getClose">
                    取消发送
                </div>
            </div>
        </div>
        <div class="padding">
            <!-- 标题 -->
            <div class="title" style="height: 120px;">
                <div class="paddings">
                    <div class="text"><span style="color: red;">*</span>通知标题</div>
                    <textarea class="textarea" maxlength="50" style="height: 60px;" v-model="value" placeholder="请输入正文"
                        cols="30" rows="10"></textarea>
                </div>
            </div>
            <!-- 正文 -->
            <div class="title">
                <div class="paddings">
                    <div class="text">通知正文</div>
                    <textarea class="textarea" maxlength="2000" v-model="values" placeholder="请输入正文" cols="30"
                        rows="10"></textarea>
                </div>
            </div>
            <!-- 添加图片 -->
            <div class="text">
                添加图片
            </div>
            <van-uploader v-model="fileList" :max-count="3" :after-read="afterRead" />

            <!-- 选择通知范围 -->
            <div class="range">
                <div class="text"><span style="color: red;">*</span>选择通知范围</div>
                <div class="texts" @click="showCheck = true">
                    <div v-if="scope" class="chioss">已选择</div>
                    <div v-else>未选</div>
                    <van-icon color="#999999" name="arrow" />
                </div>
            </div>
            <!-- 定时发送 -->
            <div class="range">
                <div class="text">定时发送</div>
                <div @click="getChecked"><van-switch v-model="checked" size="24px" /></div>
            </div>
            <div class="showtime_bottom" v-show="is_regular">
                <div class="box_bottom">
                    <div class="box_bottom_time">{{ timeValue }}</div>
                    <div class="chiosss" @click="showTime = true">重新选择</div>
                </div>
            </div>
            <div v-if="!keys" class="submit" @click="getSubmit">确认提交</div>
            <div v-else class="submit" @click="getEdit">确认修改</div>
        </div>
        <!-- 时间选择弹出框 -->
        <van-popup v-model="showTime" position="bottom" :overlay="false">
            <van-datetime-picker v-model="currentDate" type="datetime" :minHour="minHour" :maxHour="maxHour"
                :min-date="minDate" :min-minute="0" :max-minute="30" @change="changeFn()" @confirm="confirmFn()"
                :formatter="formatter" @cancel="getConfirmfn" :filter="filter" />
        </van-popup>
        <!-- 选择通知人弹出框 -->
        <van-popup v-model="showCheck" position="right" :style="popupStyle" :overlay="false">
            <div class="flex-item ">
                <div class="backcolor">
                    <span class="iconfont icon-fanhui " @click="showCheck = false">返回</span>
                </div>
                <div class="backphoto">
                </div>
            </div>
            <!--  -->
            <div class="bigBoss">
                <div class="bigBoss_left">
                    <div class="bigBoss_left_box">
                        <div class="bigBoss_left_box" :class="indexs == index ? 'bigBoss_left_boxs' : 'bigBoss_left_box'"
                            v-for="(item, index) in list" :key="index" @click="getChanges(index, item.children)">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
                <div class="bigBoss_right">
                    <div class="list">
                        <div class="list-box">
                            <div class="titile">全选</div>
                            <div @click="getAllcheck">
                                <span v-if="Allcheck" class="iconfont icon-duoxuan-xuanzhong check"></span>
                                <span v-else class="iconfont icon-yunongtongduoxuanweixuanzhong check"></span>
                            </div>
                        </div>
                        <!-- !!!! -->
                        <div class="list-box" v-for="(item, index) in listDta" :key="index">
                            <div class="titile">{{ item.name }}</div>
                            <div @click="getCheck(index, item.check)">
                                <!-- <van-icon v-if="item.check == false" name="circle" color="#165DFF" size="4vw"/>
                                <van-icon v-else name="checked" color="#165DFF" size="4vw"/> -->
                                <span v-if="item.check == false" class="iconfont icon-yunongtongduoxuanweixuanzhong check"></span>
                                <span v-else class="iconfont icon-duoxuan-xuanzhong check"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="padding display">
                <div @click="getSelectAll">
                    <span v-if="SelectAll" class="iconfont icon-duoxuan-xuanzhong check"></span>
                    <span v-else class="iconfont icon-yunongtongduoxuanweixuanzhong check"></span>
                    <span>全选</span>
                </div>
                <div class="bottom_btn" @click="getSure">
                    确定
                </div>
            </div>
        </van-popup>
        <div style="height: 49px;"></div>
        <div class="bottom">
            <div class="bottom_box">
                <van-icon @click="getUp" class="" name="arrow-left" />
                <van-icon class="icon" name="arrow" />
            </div>
        </div>
    </div>
</template>
<script>
import { NoticeAdd, NoticeEdit, NoticeGradeClasses, NoticeLock } from "../../api/notice/notice";
import { Notify, Dialog } from "vant";
import { geturl } from "@/api/leavelist";
export default {
    data() {
        return {
            value: '',
            checked: false,
            minHour: 0,
            maxHour: 24,
            minDate: new Date(),
            currentDate: new Date(),
            showTime: false,
            timeValue: '',
            showCheck: false,
            popupStyle: {
                width: '100%',
                height: '100%',
                'border-radius': '0',
            },
            indexs: 0,
            list: [],
            listDta: [],
            Allcheck: false,
            SelectAll: false,
            isshow: false,//底部全选
            values: '',
            scope: false,
            keys: false,
            is_regular: false,//是否定时
            classes_idAarr: [],
            id: '',
            fileList: [],
            file: [],
            oss_url: process.env.VUE_APP_OSS_URL,

        };
    },
    created() {
      this.getGradeClasses()
        if (this.$route.query.str !== '' && this.$route.query.str) {
            this.keys = true
            let detailArr = JSON.parse(this.$route.query.str);
            // console.log(detailArr)
            this.value = detailArr.title
            this.values = detailArr.content
            this.timeValue = detailArr.regular_time
            this.images = detailArr.images
            this.classes_idAarr = detailArr.classes_id
            this.is_regular = true
            this.key = detailArr.key
            this.scope = true
            this.id = detailArr.id
            this.checked = true
        }
    },
    methods: {
        // 返回上一页
        getUp() {
            this.$router.push("/noticeteach");
        },
        Goblak() {
            Dialog.confirm({
                title: '',
                message: '确认取消发送吗？',
                confirmButtonColor: '#5380FF'
            }).then(() => {
                // on confirm
                this.$router.push("/noticeteach");
            }).catch(() => {
                // on cancel
            });
        },
        changeFn() { // 值变化是触发
            this.changeDate = this.currentDate
        },
        confirmFn() { // 确定按钮
            this.timeValue = this.timeFormat(this.currentDate);
            this.showTime = false;
        },
        timeFormat(time) { // 时间格式化
            let year = time.getFullYear();
            let month = time.getMonth() + 1;
            let day = time.getDate();
            let hours = time.getHours();
            let minutes = time.getMinutes();
            return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes
        },
        // 点击切换
        getChanges(e, obj) {
            this.indexs = e
            this.listDta = obj
            let allChecked = this.listDta.every(item => item.check);
            if (allChecked) {
                // 全部为true
                this.Allcheck = true
            } else {
                this.Allcheck = false
            }
        },
        // 选中取消选中
        getCheck(i, v) {

            var newlistdata = []
            this.listDta.forEach((item, index) => {
                if (index == i) {
                    item.check = !item.check
                }
                newlistdata.push(item)
            })
            this.listDta = newlistdata
            let allChecked = this.listDta.every(item => item.check);
            if (allChecked) {
                // 选项 全部选中
                this.Allcheck = true
            } else {
                this.Allcheck = false
            }
            console.log(this.list)
            let isAllChecked = true;
            this.list.forEach((grade) => {
                grade.children.forEach((classItem) => {
                    if (!classItem.check) {
                        isAllChecked = false; // 如果有一个`check`属性值为`false`，即存在未勾选的班级，则设置`isAllChecked`为`false`
                    }
                });
            });
            if (isAllChecked) {
                // 全选
                this.SelectAll = true
            } else {
                this.SelectAll = false
            }

        },
        //全选
        getAllcheck() {
            this.Allcheck = !this.Allcheck
            if (this.Allcheck) {
                // 全选操作
                this.listDta.forEach(item => {
                    item.check = true
                })
            } else {
                // 全部取消选择
                this.listDta.forEach(item => {
                    item.check = false
                })
            }
            let isAllChecked = true;
            this.list.forEach((grade) => {
                grade.children.forEach((classItem) => {
                    if (!classItem.check) {
                        isAllChecked = false; // 如果有一个`check`属性值为`false`，即存在未勾选的班级，则设置`isAllChecked`为`false`
                    }
                });
            });
            if (isAllChecked) {
                // 全选
                this.SelectAll = true
            } else {
                this.SelectAll = false
            }
        },
        // 底部全选按钮
        getSelectAll() {
            this.SelectAll = !this.SelectAll
            this.isshow = !this.isshow
            if (this.SelectAll) {
                // 目前一全选
                this.list.forEach((item, index) => {
                    item.children.forEach((itemd, indexs) => {
                        itemd.check = true
                    })
                })
                this.Allcheck = true
            } else {
                // 为全选
                this.list.forEach((item, index) => {
                    item.children.forEach((itemd, indexs) => {
                        itemd.check = false
                    })
                })
                this.Allcheck = false
            }
        },
        // 选择范围确定
        getSure() {
            this.classes_idAarr = []
            this.showCheck = false
            this.list.forEach((item, index) => {
                item.children.forEach((itemd, indexs) => {
                    if (itemd.check) {
                        this.classes_idAarr.push(itemd.id)
                    }
                })
            })
            if (this.classes_idAarr.length === 0) {
                this.scope = false
            } else {
                this.scope = true
            }
        },
        // 提交按钮
        getSubmit() {
            if (this.value == '') {
                Notify({
                    message: '请输入标题',
                    duration: 1000,
                    background: '#ff4444'
                });
            }
            if (this.scope == false) {
                Notify({
                    message: '请选择通知范围',
                    duration: 1000,
                    background: '#ff4444'
                });
            }
            let is_regular = 0//定时0 否
            if (this.checked) {
                is_regular = 1
            } else {
                this.timeValue = ''
            }
            if (this.value !== '' && this.scope) {
                NoticeAdd({ title: this.value, content: this.values, is_regular: is_regular, regular_time: this.timeValue, classes_id: this.classes_idAarr, images: this.file })
                    .then((res) => {
                        if (res.code == 200) {
                            // 成功
                            Notify({
                                message: '发布成功',
                                duration: 1000,
                                background: '#1989fa'
                            });
                            setTimeout(() => {
                                this.$router.push('/noticeteach'); // 使用$router.push方法进行路由跳转
                            }, 2000); // 2000毫秒等于两秒
                        } else {
                            Notify({
                                message: res.msg,
                                duration: 1000,
                                background: '#1989fa'
                            });

                        }
                    })
            }
        },
        // 修改
        getEdit() {
            let is_regular = 0//定时0 否
            if (this.checked) {
                is_regular = 1
            }
            NoticeEdit({ id: this.id, title: this.value, content: this.values, is_regular: is_regular, regular_time: this.timeValue, classes_id: this.classes_idAarr, images: this.file })
                .then((res) => {
                    if (res.code == 200) {
                        // 成功
                        Notify({
                            message: '发布成功',
                            duration: 1000,
                            background: '#1989fa'
                        });
                        setTimeout(() => {
                            this.$router.push('/noticeteach'); // 使用$router.push方法进行路由跳转
                        }, 2000); // 2000毫秒等于两秒
                    } else {
                        Notify({
                            message: res.msg,
                            duration: 1000,
                            background: '#1989fa'
                        });
                    }
                })
        },
        // 年纪班级
        getGradeClasses() {
            NoticeGradeClasses().then((res) => {
                this.list = res.data
                this.list.forEach((item, index) => {
                    item.children.forEach((itemd, indexs) => {
                        itemd.check = false;
                    })
                })
                this.list.forEach((item) => {
                    item.children.forEach((child) => {
                        if (this.classes_idAarr.includes(child.id)) {
                            child.check = true
                        }
                    });
                });
                this.listDta = this.list[0].children
                let allChecked = this.listDta.every(item => item.check);
                if (allChecked) {
                    this.Allcheck = true
                }
            })
        },
        // 上传图片
        async afterRead(fileList) {
            var file = fileList.file;
            let f = await this.$api.compressImg(file);
            await geturl("mobile/upload/upload", f).then((res) => {
                let url = res.data.url
                this.file.push(url);
            });
        },
        // 取消发布
        getClose() {
            Dialog.confirm({
                title: '',
                message: '确认取消发送吗？',
                confirmButtonColor: '#5380FF'
            }).then(() => {
                NoticeLock({ id: this.id }).then((res) => {
                    if (res.code == 200) {
                        Notify({
                            message: '已取消',
                            duration: 1000,
                            background: '#F95E5A'
                        });
                        this.$router.push("/noticeteach");
                    } else {
                        Notify({
                            message: res.msg,
                            duration: 1000,
                            background: '#F95E5A'
                        });
                    }
                })
            }).catch(() => {
                // on cancel
            });
        },
        // 
        getChecked() {
            if (this.checked) {
                this.showTime = true
                this.is_regular = true
            } else {
                this.is_regular = false

            }
        },
        getConfirmfn() {
            this.showTime = false
            this.is_regular = false
            this.checked = false
        },
        formatter(type, value) {
            if (type === 'year') {
                return `${value}年`;
            } else if (type === 'month') {
                return `${value}月`
            } else if (type === 'day') {
                return `${value}日`
            } else if (type === 'hour') {
                return `${value}时`
            } else if (type === 'minute') {
                return `${value}分`
            }
            return value;
        },
        filter(type, options) {
            if (type === 'minute') {
                return options.filter(option => option % 30 === 0);
            }
            return options;
        },
    },
    mounted() {
        this.timeFormat(new Date());
    },
    beforeDestroy() {
    },
    watch: {
        // checked(newValue, oldValue) {
        //     if (newValue == true) {
        //         this.showTime = true
        //         this.is_regular = true
        //     }
        // }
    }
};
</script>
<style lang="less" scoped>
.flex-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
}

.backcolor {
    color: #ffffff;
    width: 52px;
    height: 24px;
    border-radius: 12px;
    background-color: #AFAFAF;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 12px;

    }
}

.padding {
    padding: 0 15px;
}

.bottom {
    position: fixed;
    bottom: 0;
    height: 49px;
    background: #F7F7F7;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .bottom_box {
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.icon {
    color: #999999;
    margin-left: 20px;
}

.title {
    width: 100%;
    height: 200px;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 0px rgba(119, 119, 119, 0.06);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-bottom: 20px;

    .text {
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 600;
        color: #555555;
        margin-bottom: 10px;
    }

    .textarea {
        width: 100%;
        height: 130px;
        border: none;
        outline: none;
        resize: none;
    }
}

.text {
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #555555;
    margin-bottom: 10px;
}

.paddings {
    padding: 15px;
}

.updata_box {
    width: 100px;
    height: 100px;
    background: #F3F3F3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .texts {
        color: #999999;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
}

.bigBoss {
    display: flex;

    .bigBoss_left {
        width: 80px;
        height: 470px;
        background: #F2F2F2;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;

        .bigBoss_left_box {
            width: 80px;
            height: 50px;
            background: #F2F2F2;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            font-size: 16px;
            color: #999999;
            line-height: 50px;
            text-align: center;
        }

        .bigBoss_left_boxs {
            width: 80px;
            height: 50px;
            background: #FAFAFA;
            font-size: 16px;
            color: #5380FF;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            line-height: 50px;
            text-align: center;
        }
    }

    .bigBoss_right {
        .list {
            width: 100%;

            .list-box {
                padding: 20px 25px;
                width: 224px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .titile {
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }
        }
    }
}

.check {
    color: #165DFF;
}

.display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 76px;
}

.bottom_btn {
    width: 260px;
    height: 36px;
    background: #5380FF;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
}

.submit {
    width: 100%;
    background-color: #5380FF;
    border-radius: 4px 4px 4px 4px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 36px;
    margin-top: 20px;
}

.chioss {
    width: 70px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #5380FF;

}

.chiosss {
    margin-left: 30px;
    width: 70px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #5380FF;
}

.showtime_bottom {
    width: 100%;
    background: #F3F3F3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-top: 20px;
    padding: 1vw;
    box-sizing: border-box;

    .box_bottom {

        display: flex;
        justify-content: space-between;

        .box_bottom_time {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 16px;
            padding-left: 10px;
        }
    }
}

.close {
    width: 64px;
    height: 24px;
    background: #F95E5A;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 24px;
}
</style>

  